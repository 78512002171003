<script>
import appConfig from "@/app.config";

import {mapState, mapGetters} from "vuex";

import simplebar from "simplebar-vue";
import { VclCode } from 'vue-content-loading';

import {with_acrf_token} from "@/methods";


export default {

  computed: {
    ...mapGetters([
      'getAccountName',
      'getAccountId'
    ])
  },
  components: {
    VclCode
  },
  methods: {
    handleError(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/support/status', {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            this.data = data;
            this.ready = true;
          })
          .catch(error => {
            this.handleError(error);
          });
    },
    async updateSupportTeamStatus() {
      try {
        let response = await fetch(process.env.VUE_APP_ROOT_API + `v1/support/info`, {
          method: 'GET',
          credentials: 'include'
        });
        if(response.ok) {
          let data = await response.json();
          this.supportTeam = data;
        } else {
          this.error = true;
          throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
        }
      } catch (error) {
        console.log(`[ERROR] ${error}`);
      }
    },
  },
  async created() {
    this.supportTeamTimer = setInterval(() => {this.updateSupportTeamStatus();}, 1000 * 30);
  },
  mounted() {
    this.updateSupportTeamStatus();
    this.getData();
  },
  async destroyed() {
    if(this.supportTeamTimer) clearInterval(this.supportTeamTimer);
  },
  data() {
    return {
      ready: false,
      error: false,
      data: null,
      supportTeamTimer: null,
      supportTeam: null,
      businessHours: {
        start: "09:00",
        end: "17:00"
      },
      businessDays: {
        start: "Monday",
        end: "Friday"
      },
    }
  }
};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <router-link to="/support/open" class="btn bg-primary btn-lg btn-light btn-block">
          <h4 class="m-0 text-uppercase">
            <i class="fad fa-map-marker-question"></i>
            {{$t('support.hotlinks.open.link')}}
          </h4>
        </router-link>
      </div>
    </div>
    <!-- Display -->
    <template v-if="ready">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('support.view.status.title')}}</h5>
              <hr>
              <div class="row">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    {{$t('support.view.status.account.id')}}
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ getAccountId() }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    {{$t('support.view.status.account.name')}}
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ getAccountName() }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    {{$t('support.view.status.account.tier')}}
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  <template v-if="data.user.tier === 'premium'">
                    <span class="badge badge-light font-size-12">
                      <i class="fas fa-user-crown"></i>
                      Early-Adopter
                    </span>
                  </template>
                  <template v-else>
                    <span class="badge badge-dark font-size-12 text-light">
                      Default
                    </span>
                  </template>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    {{$t('support.view.status.support.level')}}
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  <template v-if="data.user.support_level === 'priority'">
                    <span class="badge badge-primary font-size-12">
                      <i class="fas fa-level-up-alt"></i>
                      Priority support
                    </span>
                  </template>
                  <template v-else>
                    <span class="badge badge-dark font-size-12 text-light">
                      Normal
                    </span>
                  </template>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 col-sm-12">
                  <h6 class="m-0 text-uppercase">
                    {{$t('support.view.status.support.total')}}
                  </h6>
                </div>
                <div class="col-lg-9 col-sm-12 text-white">
                  {{ data.tickets.total }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="supportTeam">
        <div v-if="supportTeam.holiday" class="row">
          <div class="col-lg-12 mt-n3">
            <div class="card text-black bg-warning">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-2 text-black font-size-20">
                  <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.holiday.title') }}
                </h5>
                <p class="card-text text-black">
                  {{ $t('support.disclaimer.holiday.message') }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="!supportTeam.available" class="row">
          <div class="col-lg-12 mt-n3">
            <div class="card text-black bg-warning">
              <div class="card-body p-2">
                <h5 class="mt-0 mb-2 text-black font-size-20">
                  <i class="fad fa-info-circle mr-1"></i> {{ $t('support.disclaimer.officehours.title') }}
                </h5>
                <div class="card-text text-black">
                  <span>
                    {{ $t('support.disclaimer.officehours.message1') }}<br>
                    {{ $t('support.disclaimer.officehours.message2') }}
                  </span>
                  <hr class="mt-2 mb-2 p-0"/>
                  <div class="text-center">
                    {{ $t('support.disclaimer.officehours.info') }}<br>
                    <b class="ml-1">{{ businessHours.start }} - {{ businessHours.end }} / {{ businessDays.start }} - {{ businessDays.end }}</b><br>
                    <small>(UTC timezone)</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <!-- Error -->
    <template v-else-if="error">
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Loading -->
    <template v-else>
      <div class="row mt-2">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="text-uppercase">{{$t('support.view.status.title')}}</h5>
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>